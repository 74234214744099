@import '_variables';
@import '_mixins';

body {
    padding-top: 8.6rem;
    
    &.overflow {
        height: 100%;
        overflow: hidden;
    }
}

h1, .h1 {
    font-size: $font-size-xtall;
}

// Header
header {
    @include box-shadow(0 0 20px 0 rgba(0,0,0,0.10));
    
    .table {
        display: table;
        width: calc(100% + 30px);
        
        [class*="col"] {
            display: table-cell;
            vertical-align: middle; 
            float: none;   
        }
    }
    
    .header-top {
        padding: 2.2rem 2rem;
        
        nav {
            margin-right: 2rem;
        }
		
		.website {
			opacity: 0;
			visibility: hidden;
			position: absolute;
			top: 4px;
			left: 60px;
			z-index: 100;
			transition: all ease 300ms;
			
			&.show {
				opacity: 1;
				visibility: visible;
				
				.wpml-ls-legacy-dropdown-click {
					width: auto;
				}
			}
			
			.wpml-ls-legacy-dropdown-click {
				width: 0;
			}
		}
    }
    
    #menuToggle {
        input {
            &:checked ~ .menu-burger {
                @include transform(translate(0, 0));
            }
        }
        
        .menu-burger {
            width: 90%;
            padding: 8rem 2rem;
            
            .link {
                font-size: 1.6rem;
            }
        }
    }
    
    .search {
        .search-box {
            height: 9rem;
            
            form {
                .search-input {
                    margin-left: 5rem;
                }
            }
        }
    }
}

//slider
.slider {
    margin-bottom: 2rem;
    height: 35rem;
    
    article {
        height: 35rem;
        
        .photo {
            height: 35rem;
            
            img {
                width: auto;
                height: 100%;
                position: relative;
                left: 50%;
                @include transform(translateX(-50%));
            }
        }
    }
    
    .content {
        width: 90%;
        bottom: 2rem;
    }
    
    .owl-nav {
        button {
            &.owl-prev {
                left: 2rem;
            }
            
            &.owl-next {
                right: 2rem;
            }
        }
    }
}

aside {
	margin-bottom: 4rem;
	
}


// PAGE CATEGORIE


.category-hero {
	height: 34rem;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	position: relative;
	display: table;
	width: 100%;
	
	.container {
		position: relative;
		z-index: 2;
		display: table-cell;
		vertical-align: bottom;
		.title-cat {
			display: table;
			padding: .5rem 1rem;
			color: $white;
			margin: auto;
			font-weight: 700;
			margin-bottom: 1rem;
		}
		.desc-cat {
			margin-bottom: 2rem;
			p {
				color: $white;
				text-align: center;
				font-weight: 700;
				font-size: 3rem;
			}
		}
	}
}     


.title-cat-page {
	font-size: 3.5rem;
	font-weight: $light;
	margin-bottom: 3rem;
}


// Article

.post {
    margin-bottom: 4rem;
}

.single {
	.hero-post {
		h1 {
			font-size: 3rem;
		    margin-bottom: 1rem;
		}
		p {
			font-size: 2rem;
		}
		img {
			margin: 2rem 0;
		}
	}
	
	.infos-post {
		.date {
			margin-bottom: 1.8rem;
		}
		.category {
			padding-bottom: 1.5rem;
			margin-bottom: 1.5rem;
		}
		.like-it {
			padding-bottom: 1.5rem;
			margin-bottom: 1.5rem;
			p {
				span {
					font-size: 3rem;
					line-height: 2rem;
				}
			}
		}
		.tags {
    		padding-bottom: 1.5rem;
    		margin-bottom: 1.5rem;
		}
	}
	
	
	.post-content {
		h2 {
			font-size: 2.5rem;
		}
		blockquote {
			margin: 3rem 0;
			padding: 3rem 4rem;
		}
		ul, ol {
			li {
				font-size: 1.6rem;
				line-height: 2rem;
				margin-bottom: .4rem;
			}
		}
	}
	
	.title-part {
		margin-bottom: 2.5rem;
		font-size: 2rem;
	}
	
	.related {
		margin-bottom: 0;
		padding-top: 3rem;
		article {
			padding: 2rem;
		}
	}

	
}



// Archives experts

.filter {
	margin-bottom: 3rem;
	.form-filter {
		padding: 1.3rem 3rem;
		label {
			font-size: 2rem;
			margin-right: 2rem;
			padding: .4rem 0;
		}
		select {
			height: 3.5rem;
			width: 15rem;
			font-size: 1.4rem;
		}
	}
}

.company-name-cat {
	font-size: 2rem;
	margin-bottom: 2.5rem;
}



// Single expert

.hero-expert {
	margin-bottom: 3rem;
	h1 {
		font-size: 3.5rem;
		margin-bottom: 3rem;
		span {
			font-size: 2rem;
			display: block;
		}
	}
}


.categories {
	margin-bottom: 2.5rem;
	li {
		padding: .5rem 1rem;
		margin-right: 1rem;
	}
}


// ERREUR REVOIR

.infos-expert {
    display: table;
    margin:0 auto 4rem;
    .share-expert {
	    margin-bottom: 1rem;  
	    p {
		    text-align: center;
	    }
	    ul {
		    display: table;
		    margin: auto;
		    li {
			    margin-left: -.5rem;
			    &:last-child {
				    margin-left: 1rem;
			    }
			    a {
					font-size: 3rem;
				}
		    }
	    }
    }
}


.form-contact {
	padding: 2rem;
	margin-bottom: 3rem;
	.titre-form-contact {
		margin-bottom: 2.5rem;
		.circle {
			margin-right: 1rem;
		}
		p {
			line-height: 4rem;
			font-size:3.5rem;
			width:calc(100% - 5rem);
		}
	}
	form {
		.infos {
			font-size: 1.6rem;
			line-height: 2.4rem;
			margin-bottom: 2rem;
		}
		label {
			line-height: 2.8rem;
			font-size: 1.4rem;
		}
		input[type="text"],
		input[type="email"] {
			height: 5rem;
			margin-bottom: 2rem;
			font-size: 1.4rem;
			padding: 1rem;
		}
		
		select  {
			height: 5rem;
			margin-bottom: 2rem;
			font-size: 1.4rem;
			padding: 1rem;
		}
		textarea {
			height: 15rem;
			font-size: 1.4rem;
			padding: 1rem;
			margin-bottom: 2rem;
		}
	}
}



// Post linked expert aside

.content-expert-article {
	margin-bottom: 2rem;
	padding: 2.5rem;
	.title-plugin {
		margin-bottom: 3.5rem;
	}
	.expert-article {
		padding-bottom: 2.5rem;
		margin-bottom: 2.5rem;
		.topic {
			margin-bottom: 1rem;
		}
		img {
			width: 35%;
			float: left;
		}
		.title-article-expert {
			font-size: 1.6rem;
			width: 60%;
			margin-left: 5%;
			float: left;	
			line-height: 2rem;
		}
	}
}


.like-article {
	padding-top: 3rem; 
}

.share-post {
	margin:3rem auto 0;
	p {
		margin-bottom: 1rem;
		text-align: center;
	}
	ul {
		display: table;
		margin: auto;
		li {
			float: left;
			margin: 0 .4rem;
			a {
				font-size: 3rem;
			}
		}
	}
}



// List experts page 
.list-experts {
    .post-experts {
        margin-bottom: 4rem;
    }
    
    .sep-experts {
        margin: 2.5rem 0;
    }
}

// LES EXPERTS CATEGORY
.expert-categorie {
	padding: 2rem;
	margin-bottom: 2rem;
}


// LOADER
.alm-btn-wrap {
	margin-top: 4rem;
}




// PAGE INDEX

.page-type {
	h1 {
		font-size: 3.5rem;
	    margin-bottom: 3rem;
	}
	
	article {
		margin-bottom: 10rem;
		img {
			margin-bottom: 7rem;
		}
		h2 {
			font-size: 4rem;
		    margin-bottom: 1rem;
		}
		p {
			font-size: 1.8rem;
			line-height: 2.4rem;
		}
		.image_complementaire {
			margin-top: 9rem;
			position: relative;
			img {
				margin-bottom: 0;
			}
			.legende {
				background: $rouge;
				display: table;
				color:$white;
				max-width: 70%;
				padding: 3rem;
				position: absolute;
				bottom: -5rem;
				left:2.5rem;
			}
		}
		form {
			.infos {
			    font-size: 1.8rem;
				line-height: 2.4rem;
				margin-bottom: 6rem;
			}
			.infos {
			    font-size: 1.8rem;
				line-height: 2.4rem;
				margin-bottom: 6rem;
			}
			label {
			    line-height: 2.8rem;
			    font-size: 1.4rem;
			}
			input[type="text"], 
			input[type="email"] {
			    height: 5rem;
			    margin-bottom: 2rem;
			    font-size: 1.4rem;
			    padding: 1rem;
			}
			textarea {
			    height: 15rem;
			    font-size: 1.4rem;
			    padding: 1rem;
			    margin-bottom: 2rem;
			}
			select {
				height: 5rem;
			    margin-bottom: 2rem;
			    font-size: 1.4rem;
			    padding: 1rem;
			}
			.infos-rgpd {
			    font-size: 1.4rem;
			    margin: 1rem 0;
			    a {
					color: $couleur-1;
				}
			}
			#group-503 {
				margin-top: 3rem;
			}
		}
	}
}







// Footer
footer {
    nav {
        text-align: center;
        
        li {
            margin-bottom: 1rem;
        }
    }
    
    .right {
        text-align: center;
        margin-top: 4rem;
    }
}